import * as React from "react"

const Index = () => {
  return (
    <div>
      <h1>Mobile Privacy</h1>
    </div>
  )
}

export default Index
